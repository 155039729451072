<template>
  <div class="more" ref="container">
    <input
      type="text"
      class="hide_url"
      v-model="copyContent"
      style="opacity: 0"
    />

    <div class="nav-btn">
      <router-link :to="`/user/lessonNetwork`"
        ><span>课程二维码</span></router-link
      >
      <router-link :to="`/user/networkStudentList`" class="active"
        ><span>学员列表</span></router-link
      >
    </div>

    <div class="search">
      <div class="item">
        <label>课程名称：</label>
        <cw-input-select
          v-if="lessonList.length"
          ref="cwInputLesson"
          @getSearchValue="getSearchValue"
          :options="lessonList"
          label-name="label"
        ></cw-input-select>
      </div>
      <div class="item">
        <label>支付方式：</label>
        <cw-input-select
          v-if="lessonList.length"
          ref="cwInputWay"
          @getSearchValue="getSearchValue"
          :options="wayList"
          label-name="label"
        ></cw-input-select>
      </div>
      <div class="item">
        <label>日期区间：</label>
        <div class="time">
          <van-cell :value="time" @click="timeShow = true">{{
            time ? time : "选择日期区间"
          }}</van-cell>
          <van-calendar
            v-model="timeShow"
            :min-date="minDate"
            :max-date="maxDate"
            type="range"
            @confirm="onConfirm"
          />
        </div>
      </div>
      <div class="btn-style">
        <span style="float: left">总数：{{ total }}</span>
        <button class="btn-info" @click="clearParams">清除</button>
        <button class="btn-default" @click="searchEvent">搜索</button>
      </div>
    </div>

    <ul
      class="main-container"
      v-infinite-scroll="loadMore"
      infinite-scroll-distance="0"
      infinite-scroll-immediate-check="false"
    >
      <li v-for="(item, i) in teacherList" :key="i">
        <h4>{{ item.nickname }}</h4>
        <div class="line">
          <div>{{ item.bookname }}</div>
          <div>
            支付状态：
            <span v-if="item.status == -1">退款</span>
            <span v-else-if="item.status == 0">待支付</span>
            <span v-else>已支付</span>
          </div>
        </div>
        <div class="line">
          <div>学员姓名：{{ item.realname }}</div>
          <div>学员电话：{{ item.phone }}</div>
        </div>
        <div class="line">
          <div>招生导师：{{ item.enroll_name }}</div>
          <div>直播导师：{{ item.live_name }}</div>
        </div>
        <div class="line">
          <div>创建时间：{{ item.addTime }}</div>
          <div>
            金额（元）：<span>{{ item.pay_price }}</span>
          </div>
        </div>
        <div class="btn">
          <button @click="view(item)" class="btn-default">查看</button>
          <button
            class="btn-info"
            v-show="
              item.address && item.status == 1 && item.old_uid == item.uid
            "
            @click="editUid(item.id, item.uid)"
          >
            编辑学号UID
          </button>
          <button
            class="btn-info"
            v-show="!item.address && item.is_task<2 && item.status == 1 && item.source_id==0"
            @click="edit(item.id)"
          >
            编辑收货信息
          </button>
            <button
            class="btn-info"
            v-show="item.source_id==1 && !item.address && item.is_task < 1"
            @click="douyinEdit(item.id)"
          >
            编辑抖音信息
          </button>
          <button
            class="url_btn btn-success"
            v-show="!item.address && item.status == 1 && item.source_id==0"
            @click="addressLink(item.id)"
            :data-clipboard-text="copyContent"
          >
            收货地址链接
          </button>
        </div>
      </li>
    </ul>

    <Loading :loaded="loadend" :loading="loading"></Loading>

    <!-- 二维码 -->
    <mt-popup v-model="viewVisible" position="center" class="qr-img">
      <i class="jym-close dialog-close" @click="viewVisible = false"></i>
      <div class="title">学员详情</div>
      <div class="line">
        <div>
          <img
            :src="viewData.avatar"
            style="width: 1rem; height: 1rem; border-radius: 2px"
          />
        </div>
        <div>昵称：{{ viewData.nickname }}</div>
      </div>
      <div class="line">订单号：{{ viewData.order_id }}</div>
      <div class="line">课程名称：{{ viewData.bookname }}</div>
      <div class="line" v-show="viewData.source_id == 0">
        <div style="color: #26a2ff">支付金额：{{ viewData.pay_price }}</div>
        <div>
          支付状态：
          <span v-if="viewData.status == -1">退款</span>
          <span v-else-if="viewData.status == 0">待支付</span>
          <span v-else>已支付</span>
        </div>
      </div>
      <div class="line" v-show="viewData.source_id == 0">
        <div>支付方式：{{ viewData.pay_type_name }}</div>
        <div>发货状态：{{ viewData.store_status_name }}</div>
      </div>
      <div class="line">
        <div>学员姓名：{{ viewData.realname }}</div>
        <div>学员电话：{{ viewData.phone }}</div>
      </div>
      <div class="line">
        <div>招生导师：{{ viewData.enroll_name }}</div>
        <div>直播导师：{{ viewData.live_name }}</div>
      </div>
      <div class="line">
        <div>是否到校：{{ viewData.is_school == 1 ? "已到校" : "未到校" }}</div>
        <div>是否加微：{{ viewData.is_weixin == 1 ? "已加" : "未加" }}</div>
      </div>
      <div class="line">
        <div>{{ viewData.source_id == 0 ? '收货' : '抖音' }}姓名：{{ viewData.store_realname }}</div>
        <div>{{ viewData.source_id == 0 ? '收货' : '抖音' }}电话：{{ viewData.store_phone }}</div>
      </div>
<!--      <div class="line" v-show="viewData.source_id == 0"> -->
      <div class="line">
        <div style="width: 100%; white-space: initial">
          收货地址：{{ viewData.store_district }}-{{ viewData.address }}
        </div>
      </div>
      <div class="line">套盒备注：{{ viewData.remark }}</div>
      <div class="line">创建时间：{{ viewData.addTime }}</div>
      <div class="line" v-show="viewData.source_id == 0">支付时间：{{ viewData.payTime }}</div>
    </mt-popup>

    <!-- 编辑uid -->
    <mt-popup v-model="uidVisible" position="center" class="qr-input">
      <i class="jym-close dialog-close" @click="uidVisible = false"></i>
      <div class="title">编辑学号UID</div>
      <input v-model="updateUid" placeholder="请输入学号uid" />
      <div class="btn-style">
        <button @click="saveUid" class="btn-default">保存</button>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { Calendar } from "vant";
import Clipboard from "clipboard";
import Loading from "@components/Loading";
import cwInputSelect from "@components/cw-input-select";
import { studentList, studentEditUid } from "@api/user";
Vue.use(Calendar);

export default {
  name: "more",
  components: {
    Loading,
    cwInputSelect,
  },
  data() {
    return {
      lesson_id: "",
      status: "",
      copyContent: "",
      updateId: "",
      updateUid: "",
      time: "",
      timeShow: false,
      page: 1,
      limit: 10,
      total: "",
      teacherList: [],
      lessonList: [],
      viewData: {},
      minDate: new Date(2021, 0, 1),
      maxDate: new Date(2029, 12, 31),
      queryLoading: false,
      loadend: false,
      loading: false,
      viewVisible: false,
      uidVisible: false,
      clipboard: null,
      wayList: [
        { value: "", label: "请选择", sign: "way" },
        { value: -1, label: "退款", sign: "way" },
        { value: 0, label: "待支付", sign: "way" },
        { value: 1, label: "已支付", sign: "way" },
      ],
    };
  },
  created() {},
  mounted() {
    this.lesson_id = this.$route.query.id;
    this.loadMore();
    setTimeout(() => {
      let btn = document.getElementsByClassName("url_btn");
      if (btn[0]) btn[0].click();
    }, 500);
  },
  methods: {
    loadMore() {
      let that = this;
      let params = {
        lesson_id: that.lesson_id,
        status: that.status,
        time: that.time,
        page: that.page,
        limit: that.limit,
      };
      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      studentList(params).then((res) => {
        that.loading = false;
        //apply();js将一个数组插入另一个数组;
        that.teacherList.push.apply(that.teacherList, res.data.list);
        that.lessonList = res.data.lessonList.map((item) => {
          return {
            label: item.bookname,
            value: item.id,
            sign: "lesson",
          };
        });
        that.lessonList.unshift({ label: "请选择", value: "", sign: "lesson" });
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
        that.total = res.data.count;
      });
    },

    // 搜索条件赋值
    getSearchValue(val) {
      let that = this;
      val.sign == "lesson"
        ? (that.lesson_id = val.value)
        : (that.status = val.value);
    },

    // 清除搜索条件
    clearParams() {
      let that = this;
      that.$refs.cwInputLesson.selectedText = '';
      that.$refs.cwInputWay.selectedText = '';
      that.lesson_id = "";
      that.status = "";
      that.time = "";
      that.searchEvent()
    },

    // 搜索
    searchEvent() {
      let that = this;
      that.teacherList = [];
      that.page = 1;
      that.loadMore();
    },

    // 时间区间选择
    formatDate(time) {
      return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    },
    onConfirm(time) {
      const [start, end] = time;
      this.timeShow = false;
      this.time = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },

    //收货地址链接
    addressLink(id) {
      let that = this;
      that.$nextTick(() => {
        let copybtn = document.getElementsByClassName("url_btn");
        let clipboard = new Clipboard(copybtn);
        let url = location.origin + `/user/networkAddress?id=${id}`;
        that.copyContent = url;

        clipboard.on("success", function () {
          that.$dialog.success("复制成功！");
        });

        clipboard.on("error", function () {
          that.$dialog.error("复制失败！");
        });
      });
    },

    // 编辑收货地址
    edit(id) {
      this.$router.push(`/user/networkAddress?id=${id}&type=1`);
    },

    // 编辑抖音信息
    douyinEdit(id) {
      this.$router.push(`/user/trillAddress?id=${id}&type=1`);
    },

    // 编辑uid
    editUid(id, uid) {
      let that = this;      
      that.uidVisible = true;
      that.updateId = id;
      that.updateUid = uid;
    },

    // 保存uid
    saveUid() {
      let that = this;
      studentEditUid(that.updateId, that.updateUid)
        .then((res) => {
          that.$dialog.success(res.msg);
          that.teacherList = [];
          that.page = 1;
          that.loadMore();
          that.uidVisible = false;
        })
        .catch((err) => {
          that.$dialog.error(err.msg);
        });
    },

    // 查看
    view(row) {
      this.viewData = row;
      this.viewVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.more {
  padding-top: 0.88rem;
  .hide_url {
    position: absolute;
    top: 0;
  }
  .nav-btn {
    background: white;
    height: 0.88rem;
    font-size: 0.26rem;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    a {
      width: 50%;
      text-align: center;
      line-height: 0.88rem;
    }
    .active {
      color: #26a2ff;
      border-bottom: 2px solid #26a2ff;
    }
  }
  .title {
    margin-top: 0.2rem;
    font-size: 0.26rem;
    line-height: 0.6rem;
    padding-left: 0.2rem;
  }
  .search {
    margin-top: 0.2rem;
    background: white;
    font-size: 0.26rem;
    padding: 0.2rem;
    .item {
      display: flex;
      justify-content: space-between;
      height: 0.8rem;
      line-height: 0.8rem;
      label {
        width: 2rem;
      }
      .time {
        width: 100%;
        border: 1px solid #b7b7b7;
        text-align: right;
        border-radius: 4px;
        height: 0.6rem;
        line-height: 0.6rem;
        margin: 0.1rem 0;
        padding-right: 0.1rem;
        van-cell {
          width: 100%;
          color: #26a2ff;
          display: block;
        }
      }
    }
  }
  ul li {
    width: 100%;
    padding: 0.2rem 0.3rem;
    background: white;
    margin-top: 0.2rem;
    .btn {
      text-align: right;
      margin-top: 0.2rem;
      button {
        display: inline-block;
        color: white;
        font-size: 0.24rem;
        padding: 0.1rem 0.3rem;
        border-radius: 4px;
        margin-left: 0.1rem;
      }
    }
    .line {
      font-size: 0.24rem;
      display: flex;
      justify-content: space-between;
      line-height: 0.45rem;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span {
          color: #26a2ff;
        }
      }
      div:first-child {
        width: 55%;
      }
      div:last-child {
        width: 40%;
      }
    }
  }

  .mint-popup {
    width: 90%;
    padding: 0.2rem;
    .title {
      padding: 0;
      color: #26a2ff;
      margin: 0;
    }
    .line {
      font-size: 0.25rem;
      display: flex;
      justify-content: space-between;
      line-height: 0.6rem;
      > div {
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span {
          color: #26a2ff;
        }
      }
    }
    input {
      margin: 0.2rem 0;
      padding-left: 0.1rem;
      border: 1px solid #ccc;
      width: 100%;
      height: 0.68rem;
      border-radius: 4px;
      color: #666;
    }
  }
}
</style>
