<template>
  <div
    class="cw-input-select_wrap"
    v-bind:class="{ open: isShowPop }"
    onselectstart="return false"
  >
    <div class="cw-input-select">
      <div class="cw-input-select_box" v-on:click="selectHandle">
        <span>{{ selectedText || "请选择" }}</span>
        <i class="cw-arrow" v-bind:class="{ up: isShowPop }"></i>
      </div>
      <div class="cw-input-select_pop" v-if="isShowPop">
        <ul class="cw-input-select_options">
          <li v-for="(option, index) in optionsList" :key="index" v-on:click="selected(option)">
            <span v-if="typeof option == 'object'">{{
              option[labelName]
            }}</span>
            <span v-else>{{ option }}</span>
          </li>
      </ul>
        <span class="cw-input-select_arrow"></span>
      </div>
    </div>
  </div>
</template>

<script>
import "@assets/css/cw-input-select.css";
export default {
  props: ["options", "wayList", "labelName"],
  data() {
    return {
      optionsList: [],
      isShowPop: false,
      selectedText: "", // 选中文本
    };
  },
  mounted() {
    this.optionsList = this.options;
  },
  methods: {
    // 点击基本框显示或隐藏选项列表盒子
    selectHandle: function () {
      this.isShowPop = !this.isShowPop;
    },
    hidePop: function () {
      this.isShowPop = false;
    },
    // 点击选项
    selected(val) {
      this.selectedText = val.label;
      this.$emit('getSearchValue', val)
      this.isShowPop = false;
    },
  },
};
</script>